<script setup>

import { usePage } from '@inertiajs/vue3'
import Icon from '@js/Components/Icon/Icon.vue'
import { getLocalizedUrl } from '@js/App/Plugins/LocalizedUrls'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'

const { isMobile } = useIsMobile()
const page = usePage()
const payments = page.props.payments

</script>
<template>
  <div class="min-w-full max-w-full">
    <h6 class="text-base text-lg font-purple-900 font-medium mb-1 lg:mb-2">
      {{ $trans('PaymentsTable.Payments') }}
    </h6>

    <!-- Header -->
    <div class="flex w-full text-base font-medium text-purple-900 lg:text-lg break-words ">
      <div class="flex-1 bg-gray-50 rounded-tl-lg px-2 py-2">
        {{ $trans('PaymentsTable.PaidOn') }}
      </div>
      <div class="flex-1 bg-gray-100 px-2 py-2">
        {{ $trans('PaymentsTable.ValidUntil') }}
      </div>
      <div class="flex-1 bg-gray-50 rounded-tr px-2 py-2">
        {{ $trans('PaymentsTable.Amount') }}
      </div>
      <div v-if="!isMobile" class="flex-1 bg-gray-50 rounded-tr px-2 py-2">
        {{ $trans('PaymentsTable.InvoiceNo') }}
      </div>
    </div>

    <!-- Rows -->
    <div v-for="payment of payments" :key="payment.random_id" class="flex min-w-full max-w-full text-base text-purple-900 lg:text-lg">
      <div class="flex-1 bg-white rounded-tl px-2 py-2">
        {{ payment.subscription_starts_at_formatted }}
      </div>
      <div class="flex-1 bg-gray-50 px-2 py-2">
        {{ payment.subscription_ends_at_formatted }}
      </div>
      <div class="flex-1 bg-white rounded-tr px-2 py-2">
        <div class="flex justify-between">
          <div> {{ $formatPrice(payment.amount) }}</div>
          <div v-if="isMobile" class="bg-gray-100 bg-white underline underline-offset-2 text-blue-base rounded-tr-lg">
            <a
              target="_blank"
              :href="getLocalizedUrl('payments.downloadNew', {
                invoiceId: payment.invoice_id,
                createdAt: payment.subscription_starts_at_formatted,
              })"
              class="hover:cursor-pointer"
            >
              <Icon name="download" icon-width="w-[20px] lg:w-[20px]" />
            </a>
          </div>
        </div>
      </div>
      <div v-if="!isMobile" class="flex-1 bg-gray-100 bg-white underline underline-offset-2 text-blue-base rounded-tr px-[8px] py-[8px]">
        <a
          target="_blank"
          :href="getLocalizedUrl('payments.downloadNew', {
            invoiceId: payment.invoice_id,
            createdAt: payment.subscription_starts_at_formatted,
          })"
          class="hover:cursor-pointer flex items-center gap-2 "
        >
          <Icon name="download" icon-width="w-[20px] lg:w-[20px]" />
          <div>{{ payment.invoice_id }}</div>
        </a>
      </div>
    </div>
  </div>
</template>
