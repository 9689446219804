<script setup>
import Footer from '@js/Components/Marketing/Footer/Footer.vue'
import RentalSentimentHero from '@js/Components/Marketing/Heros/RentalSentimentHero/RentalSentimentHero.vue'
import Container from '@js/Components/Container/Container.vue'
import { useUser } from '@js/App/Api/User/User'
import CallToActionSection from '@js/Components/Marketing/CallToActionSection/CallToActionSection.vue'
import CallToActionSectionLoggedIn from '@js/Components/Marketing/CallToActionSection/CallToActionSectionLoggedIn.vue'
import { ref, onMounted, onUnmounted, computed } from 'vue'
import PersonTextBox from '@js/Components/MyRentbird/Offboarding/PersonTextBox/PersonTextBox.vue'
import StefInCircle from '@images/people/stef-in-circle.png'
import ApplyForRentalSentiment from '@js/Components/Marketing/ApplyForRentalSentiment/ApplyForRentalSentiment.vue'
import { useIsMobile } from '@js/App/Utils/BrowserDetection'
import Select from '@js/Components/Forms/Select/Select.vue'
import { router, usePage } from '@inertiajs/vue3'
import Chart from '@js/Components/Charts/Chart.vue'
import InfoMessageBox from '@js/Components/InfoMessageBox/InfoMessageBox.vue'
import LightBulb from '@images/icons/light.png'

const page = usePage()

const chartData = computed(() => page.props.chart_data)

const { user } = useUser()
const { isMobile } = useIsMobile()

const activeSection = ref('introductie')
const main = ref(null)
const observer = ref(null)
const navMobile = ref(null)
const obscure = ref(null)

const sections = [
  { id: 'introductie', name: 'Introductie' },
  {
    id: 'zoeken-en-huuraanbod',
    name: '1. Zoeken en huuraanbod: een uitdagende zoektocht',
    subSections: [
      {
        id: 'vertrouwen',
        name: 'Vertrouwen in platforms'
      },
      {
        id: 'lange-zoektocht',
        name: 'Lange huizenzoektocht'
      },
      {
        id: 'waarom-zo-lang',
        name: 'Waarom duurt zoeken lang?'
      },
      {
        id: 'actief-geen-garantie',
        name: 'Geen garantie op bezichtigingen'
      },
      {
        id: 'consessies-wensen',
        name: 'Concessies in woonwensen'
      },
      {
        id: 'stresszoektocht',
        name: 'Alarmerend stressniveau'
      }
    ]
  },
  {
    id: 'politiek-en-maatschappij',
    name: '2. Politiek en maatschappij: groeiend pessimisme',
    subSections: [
      {
        id: 'toekomstwoningmarkt',
        name: 'Negatief over toekomst woningmarkt'
      },
      {
        id: 'veranderingwetbetaalbarehuur',
        name: 'Draagvlak Wet Betaalbare Huur'
      },
      {
        id: 'woningmarktenstemgedraggefrustreerde',
        name: 'Woningmarkt & stemgedrag'
      },
      { id: 'emigratie', name: 'Eén derde overweegt emigratie' },
      { id: 'expats', name: 'Gemengde kijk op expats' },
      { id: 'koophuisdroom', name: 'Koophuisdroom vervaagt' }
    ]
  },
  {
    id: 'makelaars-en-verhuurpraktijken',
    name: '3. Makelaars en verhuurpraktijken: discriminatie en oplichting',
    subSections: [
      {
        id: 'wisselende-makelaarservaringen',
        name: 'Wisselende makelaarservaringen'
      },
      {
        id: 'discriminatie-veelvoorkomend',
        name: 'Discriminatie veelvoorkomend'
      },
      {
        id: 'oplichting-en-ongewenste-praktijken',
        name: 'Oplichting en ongewenste praktijken'
      }
    ]
  },
  { id: 'conclusie', name: 'Conclusie huursentiment-monitor 2024' },
  {
    id: 'deelnemers',
    name: 'Deelnemers',
    subSections: [
      {
        id: 'leeftijd',
        name: 'Leeftijd'
      },
      {
        id: 'provincies',
        name: 'Provincies'
      },
      {
        id: 'plaatsen',
        name: 'Plaatsen'
      },
      {
        id: 'netto-maandinkomen',
        name: 'Netto maandinkomen'
      },
      {
        id: 'redenen-voor-verhuizing',
        name: 'Redenen voor verhuizing'
      }
    ]
  }
]

const flattenSections = []

sections.forEach((section) => {
  flattenSections.push({ id: section.id, name: section.name })

  if (section.subSections) {
    section.subSections.forEach((subSection) => {
      flattenSections.push({
        ...subSection,
        parentId: section.id
      })
    })
  }
})

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId)
  if (section) {
    window.scrollTo({
      top: section.offsetTop - (isMobile.value ? navMobile.value.clientHeight : 0),
      behavior: 'smooth'
    })
    history.pushState(null, null, `#${sectionId}`)
  }
}

onMounted(() => {
  observer.value = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          activeSection.value = entry.target.id
        }
      })
    },
    {
      root: null,
      threshold: 0.01
    }
  )

  const sections = main.value?.children || []

  const subSections = main.value?.querySelectorAll('section span[id]') || []

  Array.from([...sections, ...subSections]).forEach((section) => {
    observer.value.observe(section)
  })

  if (isMobile.value) {
    let lastScrollTop = 0
    window.addEventListener('scroll', () => {
      const st = window.pageYOffset || document.documentElement.scrollTop
      if (st > lastScrollTop) {
        navMobile.value.style.top = `-${navMobile.value.clientHeight}px`
      } else {
        navMobile.value.style.top = '0'
      }
      lastScrollTop = st <= 0 ? 0 : st
    })
  }

  setTimeout(() => {
    obscure.value.innerHTML =
      '<' +
      'a' +
      ' ' +
      'h' +
      'r' +
      'e' +
      'f' +
      '=' +
      "'" +
      'm' +
      '&' +
      '#' +
      '9' +
      '7' +
      ';' +
      '&' +
      '#' +
      '1' +
      '0' +
      '5' +
      ';' +
      'l' +
      't' +
      'o' +
      '&#' +
      '5' +
      '8' +
      ';' +
      '&' +
      '#' +
      '1' +
      '1' +
      '5' +
      ';' +
      '&' +
      '#' +
      '3' +
      '7' +
      ';' +
      '7' +
      '&' +
      '#' +
      '5' +
      '2' +
      ';' +
      '&' +
      '#' +
      '1' +
      '01' +
      ';' +
      'f' +
      '%' +
      '&' +
      '#' +
      '5' +
      '2' +
      ';' +
      '0' +
      'r' +
      'e' +
      'n' +
      '&' +
      '#' +
      '1' +
      '1' +
      '6' +
      ';' +
      'b' +
      'i' +
      'r' +
      'd' +
      '&' +
      '#3' +
      '7' +
      ';' +
      '2' +
      'E' +
      '&' +
      '#' +
      '1' +
      '1' +
      '0' +
      ';' +
      '&' +
      '#' +
      '1' +
      '0' +
      '8' +
      ';' +
      "'" +
      '>' +
      '&' +
      '#' +
      '1' +
      '1' +
      '5' +
      ';t' +
      '&' +
      '#' +
      '1' +
      '0' +
      '1' +
      ';' +
      'f' +
      '&' +
      '#' +
      '6' +
      '4' +
      ';' +
      '&' +
      '#' +
      '1' +
      '1' +
      '4' +
      ';' +
      'e' +
      '&' +
      '#' +
      '1' +
      '1' +
      '0;' +
      't' +
      'b' +
      'i' +
      '&' +
      '#' +
      '1' +
      '1' +
      '4' +
      ';' +
      'd' +
      '&' +
      '#' +
      '4' +
      '6' +
      ';' +
      'n' +
      '&' +
      '#' +
      '1' +
      '0' +
      '8' +
      ';' +
      '<' +
      '/a' +
      '>'
  }, 7500)
})

onUnmounted(() => {
  if (observer.value) {
    observer.value.disconnect()
  }
})
</script>

<template>
  <RentalSentimentHero />
  <Container>
    <div class="flex flex-wrap">
      <nav v-if="isMobile" ref="navMobile" class="w-full sticky top-0 bg-white py-4 z-50">
        <p class="font-bold mb-4">
          Inhoudsopgave
        </p>
        <Select
          v-model="activeSection"
          class="min-w-[120px] mt-[4px]"
          :options="
            flattenSections.map((item) => ({
              label: item.name,
              value: item.id
            }))
          "
          @update:model-value="(sectionId) => scrollToSection(sectionId)"
        />
      </nav>

      <nav v-if="!isMobile" class="lg:w-1/4 sticky top-0 h-screen overflow-scroll no-scrollbar">
        <p class="font-bold mb-4">
          Inhoud
        </p>
        <ul class="list-none">
          <li v-for="(section, index) in sections" :key="index">
            <a
              :class="{
                'bg-blue-50 fake-font-bold': activeSection === section.id
              }"
              class="block p-4 hover:bg-blue-100 hover:fake-font-bold hover:cursor-pointer"
              @click="scrollToSection(section.id)"
            >
              {{ section.name }}
            </a>

            <ul v-if="section.subSections" class="pl-4">
              <li
                v-for="(subSection, subIndex) in section.subSections"
                :key="subIndex"
                :class="{
                  'bg-blue-50 fake-font-bold': activeSection === subSection.id
                }"
                class="block p-2 hover:bg-blue-100 hover:fake-font-bold hover:cursor-pointer"
                @click="scrollToSection(subSection.id)"
              >
                {{ subSection.name }}
              </li>
            </ul>
          </li>
        </ul>
      </nav>

      <main ref="main" class="w-full lg:w-3/4 lg:px-8 space-y-16">
        <section id="introductie">
          <InfoMessageBox v-if="isMobile" color="gray" class="my-8" :image="LightBulb">
            Let op: het onderzoek en de grafieken zijn beter te bekijken zijn via desktop of tablet.
          </InfoMessageBox>

          <h2 class="text-5xl font-bold break-words mb-4">
            Introductie
          </h2>
          <p class="mb-8">
            Het is geen geheim dat de Nederlandse huurmarkt knap lastig is geworden – en dat is nog zacht uitgedrukt.
            Maar hoe erg is het nou écht? Om dat uit te zoeken deed Rentbird onderzoek onder 1.738 woningzoekers over
            drie thema’s: welke hobbels kom je tegen als je een huurhuis zoekt? Wat doet deze crisis met het vertrouwen
            in de politiek? En hoe zit het eigenlijk met die makelaars en verhuurders: gaat alles wel eerlijk?
          </p>

          <ApplyForRentalSentiment />

          <h4 class="text-2xl font-bold mt-10 mb-4">
            Teaser: één op de drie woningzoekers overweegt emigratie
          </h4>

          <p class="mb-8">
            De ervaringen van de woningzoekers liegen er niet om. De helft van de zoekers is al meer dan een half jaar
            bezig met hun huizenjacht, waarbij de stress behoorlijk oploopt. Sterker nog: de helft van de ondervraagden
            loopt op hun tandvlees. Het is zelfs zo gortig dat één op de drie mensen erover denkt om hun geluk in het
            buitenland te zoeken. Benieuwd hoe dit precies zit? Duik met ons mee in de cijfers!
          </p>
          <hr class="my-8">
        </section>

        <section id="zoeken-en-huuraanbod">
          <h2 class="text-5xl font-bold break-words mb-4">
            1. Zoeken en huuraanbod een uitdagende zoektocht
          </h2>
          <p class="mb-8">
            De Nederlandse huurmarkt is momenteel één grote puzzel waar veel mensen hun tanden op stukbijten. Bijna de
            helft van de huizenzoekers is al meer dan een half jaar op huizenjacht en één op de drie zoekt zelfs al
            langer dan een jaar. Het eindeloos zoeken en reageren op woningen hakt er behoorlijk in. De helft van de
            woningzoekers loopt rond met ongezond hoge stress.
          </p>

          <span id="vertrouwen">
            <h3 class="text-3xl font-bold mt-10 mb-4">Woningzoekers vertrouwen vooral op online platforms</h3>
            <p class="mb-8">
              Ruim 8 op de 10 gebruikt verhuurwebsites zoals Pararius en meer dan 60% maakt gebruik van
              zoekbotaanbieders zoals Rentbird. De traditionele zoekmethoden blijven ook belangrijk: de helft van de
              woningzoekers zet sociale media in en een vergelijkbaar percentage zoekt via hun netwerk van vrienden en
              familie. Opvallend is dat slechts 27% via een makelaar zoekt en nog minder (14%) via een aanhuurmakelaar,
              wat suggereert dat woningzoekers vooral zelf actief zoeken naar een huurwoning via digitale kanalen.
            </p>
            <Chart :is-embed="false" :data="chartData.kanalenhuurwoning" />
          </span>

          <span id="lange-zoektocht">
            <h3 class="text-3xl font-bold mt-10 mb-4">Lange huizenzoektocht</h3>
            <p class="mb-8">
              Uit het onderzoek blijkt dat woningzoekenden lang moeten zoeken naar een geschikte huurwoning. De cijfers
              laten een zorgwekkend beeld zien.
            </p>
            <ul class="my-10">
              <li>47% van de woningzoekenden is langer dan 6 maanden op zoek</li>
              <li>30% zoekt meer dan 12 maanden</li>
              <li>15% zoekt zelfs al langer dan 2 jaar</li>
            </ul>
            <Chart :is-embed="false" :data="chartData.lengtezoektocht" />
          </span>

          <span id="waarom-zo-lang">
            <h3 class="text-3xl font-bold mt-10 mb-4">Waarom duurt de huizenzoektocht zo lang?</h3>
            <p class="mb-8">
              Voor actieve woningzoekers zijn er verschillende struikelblokken waardoor ze nog geen huurwoning hebben
              gevonden. Dit zijn de belangrijkste redenen.
            </p>
            <ul class="my-10">
              <li>Te hoge huurprijzen (58,5%)</li>
              <li>Te veel concurrentie (56,9%)</li>
              <li>Te weinig aanbod in de regio (56,6%)</li>
              <li>Te lange wachtlijsten voor sociale huurwoningen (54,1%)</li>
            </ul>
            <Chart :is-embed="false" :data="chartData.waaromlengtezoektocht" />

            <h4 class="text-2xl font-bold mt-10 mb-4">Afwijkende redenen bij een inkomen boven de € 5.000 p/mnd</h4>
            <p class="mb-8">
              Te hoge huurprijzen en te lange wachtlijsten voor sociale huur spelen een kleinere rol bij respondenten
              met hoge inkomens (€ 5000 per maand of meer). Daar is met name te grote concurrentie van andere
              woningzoekenden een veelgenoemde reden (62,4%) dat ze nog geen sleutel van een nieuwe huurwoning hebben.
            </p>
          </span>

          <span id="actief-geen-garantie">
            <h3 class="text-3xl font-bold mt-10 mb-4">Actief zoeken is géén garantie op bezichtigingen</h3>
            <p class="mb-8">
              Ondanks dat veel respondenten actief op zoek zijn en regelmatig reageren op woningaanbod, zorgt dit niet
              automatisch voor bezichtigingsmogelijkheden. Dit maakt het vinden van een geschikte woning nog
              uitdagender.
            </p>
            <ul class="my-10">
              <li>47% heeft nog geen enkele woning kunnen/mogen bezichtigen</li>
              <li>40% heeft tussen de 1 en 5 woningen bezichtigd</li>
              <li>Slechts 13% heeft meer dan 5 woningen kunnen bezichtigen</li>
            </ul>
            <Chart :is-embed="false" :data="chartData.hoeveelbezichtigingen" />
          </span>

          <span id="consessies-wensen">
            <h3 class="text-3xl font-bold mt-10 mb-4">
              Bijna alle woningzoekers moeten hun woonwensen omlaag bijstellen
            </h3>
            <p class="mb-8">
              Het vinden van een geschikte woning die aansluit bij de persoonlijke woonwensen blijkt voor veel
              woningzoekers een enorme uitdaging. 77% van de respondenten ontevreden over de hoeveelheid beschikbare
              woningen. En 55% is ontevreden over de kwaliteit van het aanbod.
            </p>
            <Chart :is-embed="false" :data="chartData.woonwensenbijstellen" />

            <h4 class="text-2xl font-bold mt-10 mb-4">De krappe woningmarkt dwingt mensen om concessies te doen</h4>
            <p class="mb-8">
              Voor 76% van de respondenten geldt dat ze ontevreden zijn met de beschikbaarheid huurwoningen die aan de
              woonwensen voldoen. Hierdoor kan vrijwel niemand vasthouden aan hun oorspronkelijke wensenlijst: 78% moet
              redelijk wat tot zeer veel concessies doen.
            </p>
            <Chart :is-embed="false" :data="chartData.tevredenheidbeschikbaarheid" />
            <Chart :is-embed="false" :data="chartData.concessieszoekcriteria" />
          </span>

          <span id="stresszoektocht">
            <h3 class="text-3xl font-bold mt-10 mb-4">Alarmerend hoog stressniveau onder huizenzoekers</h3>
            <p class="mb-8">
              De zoektocht naar een huurwoning in Nederland heeft zich ontwikkeld tot een slopend traject. Ongeveer de
              helft van de respondenten kampt met extreme stress, waarbij de impact op hun dagelijks leven aanzienlijk
              is. Deze zorgwekkende cijfers tonen aan dat de gespannen huurmarkt een directe aanslag is op het welzijn
              van woningzoekenden.
            </p>
            <ul class="my-10">
              <li>33,4% ervaart 'zeer veel stress' (score 7 uit 7)</li>
              <li>16,8% rapporteert 'veel stress' (score 6 uit 7)</li>
            </ul>
            <Chart :is-embed="false" :data="chartData.stresszoektocht" />

            <h4 class="text-2xl font-bold mt-10 mb-4">Meer stress bij een langere huizenzoektocht (> 1 jaar)</h4>
            <p class="mb-8">
              Een langere zoektocht naar een woning heeft een steeds grotere impact op het mentale welzijn van
              woningzoekenden. Uit ons onderzoek blijkt dat:
            </p>
            <ul class="my-10">
              <li>Slecht 5% weinig tot geen stress ervaart</li>
              <li>77% concessies moet doen op de oorspronkelijke woonwensen</li>
              <li>47% van de langdurig zoekenden overweegt te emigreren vanwege de situatie</li>
            </ul>

            <hr class="my-8">
          </span>
        </section>

        <section id="politiek-en-maatschappij">
          <h2 class="text-5xl font-bold break-words mb-4">
            2. Politiek en maatschappij: groeiend pessimisme
          </h2>
          <p class="mb-8">
            De Nederlandse huurmarkt zit behoorlijk in de knoop en dat laat zijn sporen na. Woningzoekenden zien het
            somber in. Sterker nog, bijna driekwart van hen verwacht geen verbetering op korte termijn. Die eindeloze
            zoektocht naar een huurhuis heeft ook politieke gevolgen: mensen laten deze ervaringen meewegen bij het
            stemmen.
          </p>
          <p class="mb-8">
            Ook heeft een flink deel van de woningzoekers het helemaal gehad met de Nederlandse huizenmarkt. Zo erg
            zelfs, dat één op de drie er serieus over denkt om de koffers te pakken en te vertrekken naar het
            buitenland. Bij mensen die al lang zoeken loopt dit zelfs op tot bijna de helft! Laten we eens kijken hoe
            dit precies zit.
          </p>

          <span id="toekomstwoningmarkt">
            <h3 class="text-3xl font-bold mt-10 mb-4">
              Woningzoekers zijn negatief over de toekomst van de woningmarkt
            </h3>
            <p class="mb-8">
              Een overgrote meerderheid van de woningzoekers is somber over de ontwikkelingen op de woningmarkt. De
              aanhoudende woningcrisis en stijgende prijzen lijken hun tol te eisen in het vertrouwen van potentiële
              kopers en huurders. Hoe gefrustreerder de woningzoekers zijn, des te pessimistischer hun visie op de
              toekomst van de woningmarkt.
            </p>
            <ul class="my-10">
              <li>72% is negatief</li>
              <li>20% is neutraal</li>
              <li>8% is positief</li>
            </ul>
            <Chart :is-embed="false" :data="chartData.toekomstwoningmarkt" />
          </span>

          <span id="veranderingwetbetaalbarehuur">
            <h3 class="text-3xl font-bold mt-10 mb-4">Wet Betaalbare Huur: wat vinden woningzoekers daarvan?</h3>
            <p class="mb-8">
              De Wet Betaalbare Huur van Hugo de Jonge moet de Nederlandse huurmarkt toegankelijker maken. Het onderzoek
              laat zien hoe verschillende groepen hier tegenaan kijken.
            </p>

            <h4 class="text-2xl font-bold mt-10 mb-4">Verwachte impact</h4>
            <ul class="my-10">
              <li>47% denkt dat de wet de kans op een huurwoning vergroot</li>
              <li>25% verwacht geen verandering</li>
              <li>28% vreest dat de kans op een huurhuis kleiner wordt</li>
            </ul>
            <Chart :is-embed="false" :data="chartData.veranderingwetbetaalbarehuur" />

            <h4 class="text-2xl font-bold mt-10 mb-4">Draagvlak per groep</h4>
            <p class="mb-8">
              De Wet Betaalbare Huur wordt over het algemeen positief ontvangen. Slechts 12% van de respondenten geeft
              aan de wet geen goed idee te vinden. Wel zien we duidelijke verschillen tussen verschillende groepen
              respondenten
            </p>
            <Chart :is-embed="false" :data="chartData.dejongewetbetaalbarehuur" />
            <table class="table-auto my-8">
              <thead>
                <tr>
                  <th>Hoogste steun bij:</th>
                  <th>Lagere steun bij:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul>
                      <li>Niet-woningzoekers (68% positief)</li>
                      <li>Middeninkomens: € 3000 - € 5999 p/mnd (67% positief)</li>
                      <li>Huurders (62% positief)</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>Actieve woningzoekers (51% positief)</li>
                      <li>Mensen zonder huurwoning (46% positief)</li>
                      <li>Lage inkomens: &lt; € 3000 p/mnd (55% positief)</li>
                      <li>Hoge inkomens: > € 6000 p/mnd (58% positief)</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <Chart :is-embed="false" :data="chartData.dejongewetbetaalbarehuurinkomen" />
            <Chart :is-embed="false" :data="chartData.dejongewetbetaalbarehuurwoning" />
            <Chart :is-embed="false" :data="chartData.dejongewetbetaalbarehuurzoekende" />
          </span>

          <span id="woningmarktenstemgedraggefrustreerde">
            <h3 class="text-3xl font-bold mt-10 mb-4">De woningmarkt beïnvloedt het stemgedrag van de woningzoekers</h3>
            <p class="mb-8">
              De verhitte woningmarkt heeft niet alleen impact op het dagelijks leven van woningzoekers, maar beïnvloedt
              ook hun politieke keuzes: 43% geeft aan dat de huidige woningmarkt redelijk veel tot zeer veel invoed
              heeft op het stemgedrag (5 tot 7 op een schaal van 7). Dit wordt nog zichtbaarder bij mensen met een
              negatief sentiment: gefrustreerde woningzoekers laten hun politieke voorkeur sterker beïnvloeden dan
              niet-gefrustreerde zoekers.
            </p>
            <Chart :is-embed="false" :data="chartData.woningmarktenstemgedraggefrustreerde" />

            <h4 class="text-2xl font-bold mt-10 mb-4">De invloed van de Wet Betaalbare Huur op stemgedrag</h4>
            <p class="mb-8">
              De mening over de Wet Betaalbare Huur hangt sterk samen met politieke voorkeur. Voorstanders van de wet
              (55%) laten hun stemgedrag vaker beïnvloeden door de woningmarkt dan tegenstanders (40%).
            </p>
            <Chart :is-embed="false" :data="chartData.woningmarktenstemgedragpositiefnegatief" />
          </span>

          <span id="emigratie">
            <h3 class="text-3xl font-bold mt-10 mb-4">Doei Nederland: een derde overweegt emigratie</h3>
            <p class="mb-8">
              De woningcrisis drijft een opvallend groot deel van de respondenten richting emigratieplannen. De
              combinatie van schaarse woningen, torenhoge prijzen en lange wachtlijsten zorgt voor toenemende
              frustratie. Voor veel Nederlanders lijkt emigratie daarom een steeds realistischer alternatief. Dit speelt
              in alle leeftijdscategorieën, maar het sterkst in de groep 25-35 jaar.
            </p>
            <ul class="my-10">
              <li>22% overweegt emigratie enigszins</li>
              <li>13% overweegt emigratie serieus</li>
            </ul>
            <Chart :is-embed="false" :data="chartData.emigrerenoverwogen" />
            <Chart :is-embed="false" :data="chartData.emigrerenoverwogenleeftijd" />

            <h4 class="text-2xl font-bold mt-10 mb-4">Na een jaar zoeken wordt het buitenland héél aantrekkelijk</h4>
            <p class="mb-8">
              Hoe langer iemand zoekt naar een huis, hoe verleidelijker het wordt om naar het buitenland te vertrekken.
              De cijfers liegen er niet om: bijna de helft (47%) van de respondenten die tussen 1 en 2 jaar zoekt,
              droomt al van een leven over de grens. Bij woningzoekers die al langer dan 2 jaar bezig zijn, is dit 45%.
            </p>
            <Chart :is-embed="false" :data="chartData.emigrerenoverwogenzoekende" />

            <h4 class="text-2xl font-bold mt-10 mb-4">Somber over de huizenmarkt? Dan denkt 52% aan emigratie</h4>
            <p class="mb-8">
              Wie denkt dat het met de Nederlandse huizenmarkt voorlopig niet goedkomt, pakt eerder zijn koffers om te
              emigreren. Van de pessimistische respondenten overweegt maar liefst 52% om hun geluk elders te zoeken.
            </p>
            <Chart :is-embed="false" :data="chartData.emigrerenoverwogensomber" />
          </span>

          <span id="expats">
            <h3 class="text-3xl font-bold mt-10 mb-4">Groeiende weerstand tegen expats</h3>
            <p class="mb-8">
              De woningcrisis lijkt de houding te verharden tegenover expats die zich in Nederland vestigen, vooral
              onder specifieke groepen woningzoekenden. Dit komt mogelijk doordat zij direct concurreren met expats in
              het middensegment van de huurmarkt, waar de druk toch al hoog is.
            </p>
            <ul class="my-10">
              <li>42% staat negatief tegenover de vestiging van expats in Nederland</li>
              <li>Dit percentage stijgt naar 49% onder gefrustreerde woningzoekenden</li>
              <li>45% van de lagere inkomens (tot €2.999) is negatief over expats</li>
            </ul>
            <Chart :is-embed="false" :data="chartData.expatsverhuizen" />
            <Chart :is-embed="false" :data="chartData.expatsverhuizengesplitst" />
          </span>

          <span id="koophuisdroom">
            <h3 class="text-3xl font-bold mt-10 mb-4">De droom van een koophuis vervaagt</h3>
            <p class="mb-8">
              Het traditionele plaatje van een eigen huis blijkt voor veel mensen steeds meer een verre droom. De
              realiteit is dat bijna de helft (46%) van de respondenten de kans klein acht dat ze ooit een woning kunnen
              kopen.
            </p>
            <p class="mb-8">
              De financiële situatie speelt hierbij een doorslaggevende rol. Met een gezamenlijk inkomen tot € 3.000 per
              maand ziet slechts 31% een eigen huis in het verschiet. Bij de groep die maandelijks meer dan € 6.000
              verdient, ziet het plaatje er een stuk rooskleuriger uit. Maar liefst 60% van hen verwacht ooit de sleutel
              van een eigen woning in handen te krijgen.
            </p>
            <Chart :is-embed="false" :data="chartData.toekomsthuiskopen" />
            <Chart :is-embed="false" :data="chartData.toekomsthuiskopengesplitst" />

            <hr class="my-8">
          </span>
        </section>

        <section id="makelaars-en-verhuurpraktijken">
          <h2 class="text-5xl font-bold break-words mb-4">
            3. Makelaars en verhuurpraktijken: discriminatie en oplichting
          </h2>
          <p class="mb-8">
            De frustraties op de huurmarkt worden versterkt door negatieve ervaringen met makelaars en verhuurders. Vier
            op de tien woningzoekers is al eens tegen een dichte deur aangelopen vanwege wie ze zijn. Of beter gezegd,
            wat hun inkomen, geboortejaar of achternaam zegt over hen. Daarnaast is meer dan een kwart van de
            huurhuiszoekers al eens in aanraking gekomen met praktijken die het daglicht niet kunnen verdragen. Deze
            cijfers tonen aan dat de zoektocht naar een huurwoning niet alleen wordt bemoeilijkt door krapte, maar ook
            door oneerlijke praktijken in de sector.
          </p>

          <span id="wisselende-makelaarservaringen">
            <h3 class="text-3xl font-bold mt-10 mb-4">Ervaringen met makelaars zijn wisselend</h3>
            <p class="mb-8">
              Deze cijfers laten zien dat de meningen over makelaars behoorlijk verdeeld zijn. Waar ongeveer een vijfde
              van de woningzoekenden positief is, heeft ruim een kwart juist negatieve ervaringen. Het grote
              middensegment lijkt vooral pragmatisch in hun beoordeling. Ze zien makelaars als een noodzakelijke schakel
              in het proces, zonder uitgesproken positief of negatief te zijn.
            </p>
            <ul class="my-10">
              <li>19,8% heeft overwegend positieve ervaringen met makelaars</li>
              <li>51,5% is neutraal over makelaars</li>
              <li>28,5% heeft overwegend slechte ervaringen met makelaars</li>
            </ul>
            <Chart :is-embed="false" :data="chartData.ervaringmakelaars" />
          </span>

          <span id="discriminatie-veelvoorkomend">
            <h3 class="text-3xl font-bold mt-10 mb-4">40% ervaart discriminatie op de woningmarkt</h3>
            <p class="mb-8">
              Het vinden van een woning blijkt voor veel mensen niet alleen een kwestie van beschikbaarheid en
              betaalbaarheid – maar ook van wie je bent. 40% van de respondenten ervaart discriminatie op de
              woningmarkt. En vooral de woningzoekers tussen de 18 en 25 jaar hebben het zwaar te verduren: 76% heeft
              zich ooit gediscrimineerd gevoeld.
            </p>
            <p class="mb-8">De vormen van discriminatie lopen uiteen, maar enkele springen er duidelijk uit:</p>
            <ul class="my-10">
              <li>65% op basis van inkomen/financieel profiel</li>
              <li>39% op basis van leeftijd</li>
              <li>35% op basis van etniciteit/afkomst</li>
            </ul>
            <Chart :is-embed="false" :data="chartData.discriminatiezoekproces" />
            <Chart :is-embed="false" :data="chartData.discriminatiezoekprocesmanier" />
            <Chart :is-embed="false" :data="chartData.discriminatiezoekprocesleeftijd" />
          </span>

          <span id="oplichting-en-ongewenste-praktijken">
            <h3 class="text-3xl font-bold mt-10 mb-4">
              27% is ooit opgelicht of had te maken met ongewenste verhuurpraktijken
            </h3>
            <p class="mb-8">
              Een aanzienlijk deel van de respondenten heeft te maken gehad met malafide verhuurpraktijken. Meer dan een
              kwart van alle huurders heeft ooit te maken gehad met oplichting of ongewenst gedrag van verhuurders. De
              problemen die huurders tegenkomen zijn divers, maar vooral financieel misbruik en verwaarlozing van
              onderhoud komen vaak voor.
            </p>
            <ul class="my-10">
              <li>46% kreeg ongewenst kosten in rekening</li>
              <li>45% kreeg te maken met weigering van essentiële reparaties</li>
              <li>30% zag valse advertenties van woningen</li>
              <li>25% kreeg te maken met ongewenst binnentreden van de verhuurder</li>
              <li>25% werd opgelicht door een tussenpersoon of makelaar</li>
            </ul>
            <Chart :is-embed="false" :data="chartData.ongewensteverhuurpraktijkenofoplichting" />
            <Chart :is-embed="false" :data="chartData.ongewensteverhuurpraktijkenofoplichtingvorm" />

            <hr class="my-8">
          </span>
        </section>

        <section id="conclusie">
          <h2 class="text-5xl font-bold break-words mb-4">
            Conclusies Huursentiment-Monitor 2024
          </h2>
          <p class="mb-8">
            Dit onderzoek toont aan dat de Nederlandse huurmarkt onder grote druk staat. De combinatie van lange
            zoektijden, hoge stress, discriminatie en een pessimistische toekomstvisie zorgt voor een zorgwekkende
            situatie. Vooral het hoge percentage jongeren dat emigratie overweegt is een alarmerend signaal.
          </p>

          <InfoMessageBox color="gray" class="my-8" :flex="false">
            Voor meer informatie over dit onderzoek kun je contact opnemen met Stef van Vliet (CEO) via
            <span ref="obscure" />
          </InfoMessageBox>

          <hr class="my-8">
        </section>

        <section id="deelnemers">
          <h2 class="text-5xl font-bold break-words mb-4">
            Over de deelnemers aan het onderzoek
          </h2>

          <p class="mb-8">
            Dit onderzoek onder 1.738 respondenten weerspiegelt vooral de situatie van jonge, stedelijke huurders en
            woningzoekenden. Dit is de groep die het meest direct geraakt wordt door de huidige krapte op de
            woningmarkt.
          </p>

          <p class="mb-8">
            Twee derde van de deelnemers is jonger dan 31 jaar, met een sterke vertegenwoordiging uit de Randstad
            (62,5%). Het gemiddelde netto huishoudinkomen ligt tussen de € 2.000 en € 3.999 per maand (45,1%). De
            grootste respons komt uit Amsterdam (10,9%), Rotterdam (10,2%) en Utrecht (8,2%).
          </p>

          <p class="mb-8">
            Van de respondenten is meer dan de helft (944 personen) op zoek naar een huurwoning, waarvan 73% actief
            zoekt. De belangrijkste verhuismotieven zijn samenwonen of het verlaten van het ouderlijk huis (24,7%),
            behoefte aan een grotere woning (19,5%) en werk- of studieredenen (17,6%).
          </p>

          <span id="leeftijd"><Chart :is-embed="false" :data="chartData.leeftijdsopbouw" /></span>
          <span id="provincies"><Chart :is-embed="false" :data="chartData.demografieprovincies" /></span>
          <span id="plaatsen"><Chart :is-embed="false" :data="chartData.demografieplaatsen" /></span>
          <span id="netto-maandinkomen"><Chart :is-embed="false" :data="chartData.inkomensverdeling" /></span>
          <span id="redenen-voor-verhuizing"><Chart :is-embed="false" :data="chartData.redenenverhuizen" /></span>

          <ApplyForRentalSentiment />

          <PersonTextBox
            :light="true"
            :image="StefInCircle"
            :no-qoute="true"
            max-width-on-desktop="lg:w-full"
            class="mt-8 mb-32"
          >
            <template #name>
              Stef<br>
              <span class="text-base">{{ $trans('Common.CoFounder') }}</span>
            </template>
          </PersonTextBox>
        </section>
      </main>
    </div>
  </Container>

  <CallToActionSectionLoggedIn v-if="user.isLoggedIn" />
  <CallToActionSection v-else />

  <Footer />
</template>
