<script setup>
import TextInput from '@js/Components/Forms/TextInput/TextInput.vue'
import Button from '@js/Components/Forms/Button/Button.vue'
import { ref } from 'vue'
import { submit } from '@js/App/Api/RentalSentimentMonitor'

const emailInput = ref('')
const errors = ref({})
const success = ref(false)

const onClickedSubmit = async () => {
  const { errors: apiErrors } = await submit(emailInput.value)
  errors.value = apiErrors

  if (Object.keys(errors.value).length <= 0) {
    emailInput.value = ''
    success.value = true
  }
}
</script>
<template>
  <div class="bg-purple-50 px-6 py-8 rounded-md lg:px-8 lg:py-8 my-7">
    <h4 class="text-xl font-bold mb-4">
      Volgend jaar ook meedoen?
    </h4>
    <p class="mb-4">
      Wij zijn benieuwd naar jouw mening en ervaring als woningzoeker. Meld je vast aan om in 2025 mee te doen aan ons
      onderzoek. Je krijgt een mailtje als het zover is.
    </p>
    <div
      v-if="!success"
      class="lg:flex"
      :class="{
        'lg:items-end': !errors.email,
        'lg:items-center': errors.email
      }"
    >
      <TextInput
        v-model="emailInput"
        class="mb-[8px] lg:mb-0 grow lg:mr-2"
        placeholder="birdie@email.com"
        :error="errors.email"
      >
        <template #label>
          {{ $trans('ApplyForRentalSentiment.EmailInputLabel') }}
        </template>
      </TextInput>

      <Button :big="true" icon-right="arrow-right" :full-width-on-mobile="true" @click="onClickedSubmit">
        {{ $trans('ApplyForRentalSentiment.EmailInputButton') }}
      </Button>
    </div>
    <div v-else>
      Bedankt, je hebt je aangemeld voor het onderzoek!
    </div>
  </div>
</template>
