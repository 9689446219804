import { doPostRequest } from '@js/App/Api/Infra/ApiClient'

export const submit = async (email) => {
  const postData = {
    email
  }

  const response = await doPostRequest('/api/huursentiment-monitor/new-lead', postData)

  if (response.hasErrors) {
    return {
      errors: response.errors
    }
  }
  return { errors: {} }
}
